import React, { Component, Suspense } from 'react';
import {BrowserRouter, HashRouter, Route, Routes} from 'react-router-dom';
import './scss/style.scss';
import ProtectedRoute from './config/ProtectedRoute'; // Import the ProtectedRoute component

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Callback = React.lazy(() => import('./views/pages/callback/Callback'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

// Email App
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'));

class App extends Component {
    render() {
        return (
            <>
                {/* Use BrowserRoute for the /callback route*/}
                <BrowserRouter>
                    <Suspense fallback={loading}>
                        <Routes>
                            <Route exact path="/login" name="Login Page" element={<Login />} />
                            <Route exact path="/register" name="Register Page" element={<Register />} />
                            <Route exact path="/404" name="Page 404" element={<Page404 />} />
                            <Route exact path="/500" name="Page 500" element={<Page500 />} />
                            <Route path="/apps/email/*" name="Email App" element={<EmailApp />} />
                            <Route path="*" element={<ProtectedRoute element={DefaultLayout} />} />
                            <Route exact path="/callback" name="Please Wait" element={<Callback />}/>
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </>
        );
    }
}

export default App;